import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default ({
  title,
  content,
  okText = "Ok",
  open,
  fullWidth,
  fullScreen,
  maxWidth,
  okColor,
  onCancel,
  onConfirm,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth || "xs"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm} disabled={loading}>
          {loading ? "Loading..." : okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
