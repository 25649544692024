import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

export default ({
  fullWidth,
  label,
  id,
  name,
  type,
  StartAdornment,
  EndAdornment,
  multiline,
  onChange,
  value,
  disabled,
}) => {
  return (
    <Box >
      <FormControl fullWidth={fullWidth}>
        <InputLabel htmlFor={id || 'custom-input'}>{label}</InputLabel>
        <Input
          id={id || 'custom-input'}
          type={type || 'text'}
          name={name}
          multiline={multiline || false}
          onChange={onChange}
          defaultValue={value}
          disabled={disabled}
          startAdornment={
            StartAdornment && (
              <InputAdornment position='start'>
                <StartAdornment />
              </InputAdornment>
            )
          }
          endAdornment={
            EndAdornment && (
              <InputAdornment position='end'>
                <EndAdornment />
              </InputAdornment>
            )
          }
        />
      </FormControl>
    </Box>
  );
};
