import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Mail from "@material-ui/icons/Mail";
import { Link, Redirect } from "react-router-dom";
import {
	Button,
	Typography,
	CircularProgress,
	IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../../components/Inputs/TextField";
import googleLogo from "../../../assets/images/googleLogo.png";
import Alert from "../../../components/alerts/FixedAlert";
import authComponentStyles from "../../../assets/styles/components/authComponentStyles";

const useStyles = makeStyles((theme) => authComponentStyles(theme));

export default ({ loginWithGoogle, authState, loginWithPassword, redUrl }) => {
	const classes = useStyles();
	const [state, setState] = useState({
		password: "",
		email: "",
		showPassword: false,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.id]: event.target.value, typing: true });
	};

	const handleClickShowPassword = () => {
		setState({ ...state, showPassword: !state.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleLogin = () => {
		const { email, password } = state;
		loginWithPassword(email, password);
		setState({ ...state, typing: false });
	};

	const { loading, error, message } = authState;

	return (
		<Box>
			<Grid container justify="center" direction="column">
				<TextField
					label="Email"
					fullWidth
					onChange={handleChange}
					id="email"
					type="email"
					EndAdornment={() => <Mail />}
				/>
				<TextField
					label="Password"
					fullWidth
					id="password"
					type={state.showPassword ? "text" : "password"}
					onChange={handleChange}
					EndAdornment={() => (
						<IconButton onClick={handleClickShowPassword}>
							{state.showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					)}
				/>
			</Grid>
			<Grid container justify="center" direction="column">
				{error && !state.typing ? (
					<Alert type="error" message={message} title="" />
				) : null}
				<Typography align="center">
					Don't have an account&nbsp;
					<Link to={`/signup?redUrl=${redUrl || "/"}`} className={classes.link}>
						Signup
					</Link>
				</Typography>
				{loading ? (
					<CircularProgress
						variant="indeterminate"
						className={classes.progress}
					/>
				) : null}
				<Button
					onClick={handleLogin}
					disabled={loading}
					className={classes.loginButton}
				>
					Login
				</Button>
				<Typography align="center">Or</Typography>
				<Button
					onClick={loginWithGoogle}
					disabled={loading}
					className={classes.googleButton}
				>
					<Grid container justify="center" alignItems="center">
						<img src={googleLogo} alt="" className={classes.authIcon} />
						&nbsp;Login With Google
					</Grid>
				</Button>
			</Grid>
			<Typography align="center" style={{ padding: "10px 0" }}>
				Forgot your password?&nbsp;
				<Link to={`/forgot_password?redUrl=${redUrl}`} className={classes.link}>
					Reset
				</Link>
			</Typography>
		</Box>
	);
};
