import React from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import LoginContainer from '../containers/auth/LoginContainer';

export default () => {
  return (
    <AuthLayout title="Welcome Back To Noneho Platform">
      <LoginContainer />
    </AuthLayout>
  );
};
