import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../../components/Inputs/TextField";
import Alert from "../../../components/alerts/FixedAlert";
import authComponentStyles from "../../../assets/styles/components/authComponentStyles";

const useStyles = makeStyles((theme) => authComponentStyles(theme));

export default ({ onContinue, authState, OnChange, onSkip }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    OnChange(event.target.name, event.target.value);
  };

  const { loading, error, message } = authState;

  return (
    <Box>
      <Grid container justify="center" direction="column">
        <Typography align="center">
          Would you like to create an organization? This will help you manage
          your data and add colaborators. Please provide the name and continue
          or click skip
        </Typography>
        <br></br>
        <TextField
          label="Name"
          fullWidth
          onChange={handleChange}
          id="name"
          name="name"
        />
        <TextField
          label="Email"
          fullWidth
          onChange={handleChange}
          id="email"
          name="email"
          type="email"
        />
        <TextField
          label="Phone Number"
          fullWidth
          onChange={handleChange}
          id="phone"
          name="phone"
          type="tel"
        />
      </Grid>
      <Grid container justify="center" direction="column">
        {error ? <Alert type="error" message={message} title="" /> : null}
        {loading ? (
          <CircularProgress
            variant="indeterminate"
            className={classes.progress}
          />
        ) : null}
        <Grid container justify="flex-end">
          <Button disabled={loading} onClick={onSkip}>
            Skip
          </Button>
        </Grid>
        <Button
          onClick={onContinue}
          disabled={loading}
          className={classes.loginButton}
        >
          Continue
        </Button>
      </Grid>
    </Box>
  );
};
