import { defaultFont, dangerColor, whiteColor } from '../base';

import customDropdownStyle from './customDropdownStyle';

const adminNavbarLinksStyle = (theme) => ({
  ...customDropdownStyle(theme),
  wrapper: {},
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0!important',
    textTransform: 'none',
  },
  buttonLink: {
    margin: '5px 15px 0',
  },
  top: {
    zIndex: '4',
  },
  links: {
    width: '30px',
    height: '30px',
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '30px',
      height: '30px',
      color: 'inherit',
      opacity: '0.8',
      marginRight: '16px',
      marginLeft: '-5px',
    },
  },
  notifications: {
    zIndex: '4',
    position: 'absolute',
    top: '5px',
    border: '1px solid ' + whiteColor,
    right: '5px',
    fontSize: '9px',
    background: dangerColor[0],
    color: whiteColor,
    minWidth: '16px',
    height: '16px',
    borderRadius: '10px',
    textAlign: 'center',
    lineHeight: '14px',
    verticalAlign: 'middle',
    display: 'block',
  },
  managerClasses: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  headerLinksSvg: {
    width: '30px !important',
    height: '30px !important',
    borderRadius: '50%',
  },
  dropdown: {
    maxWidth: 400,
  },
  notificItem: {
    '&:hover': {
      background: 'rgba(0,0,0,0.02)',
      cursor: 'pointer',
    },
  },
});

export default adminNavbarLinksStyle;
