import React from 'react';
import AppLayout from '../../../layouts/DashboardLayout';
import UserAccountContainer from '../../containers/users/UserAcountContainer'

export default () => {
  return (
    <AppLayout>
      <UserAccountContainer/>
    </AppLayout>
  );
};
