import React from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import SignupContainer from '../containers/auth/SignupContainer';

export default () => {
  return (
    <AuthLayout>
      <SignupContainer />
    </AuthLayout>
  );
};
