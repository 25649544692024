import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import Tab from '@material-ui/core/Tab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Edit from '@material-ui/icons/Edit';
import CustomCard from '../../../components/cards/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubHeader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mobTabs: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  deskTabs: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Container>
      <Paper className={classes.root} elevation={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='Personal Info' />
          <Tab label='My Apps' />
          <Tab label='Organizations' />
        </Tabs>
      </Paper>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box>
            <Typography variant='h5'>Personal Information</Typography>
            <CustomCard
              body={
                <Box>
                  <List>
                    <ListSubHeader>Basic Account Info</ListSubHeader>
                    <ListItem>
                      <ListItemText primary='Profile Photo' />
                      <ListItemSecondaryAction>
                        <img
                          style={{ borderRadius: '50%' }}
                          src='https://lh5.googleusercontent.com/-59q3kXYM-so/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucm9BhDwiIH8HMNERlZxSOGpJ-Xznw/s96-c/photo.jpg'
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary='Account Type'
                        secondary='Personal'
                      />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Edit fontSize='small' color='primary' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary='Display Name'
                        secondary='Francois Mugorozi'
                      />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Edit fontSize='small' color='primary' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <List>
                    <ListSubHeader>Contact Info</ListSubHeader>
                    <ListItem>
                      <ListItemText
                        primary='Email'
                        secondary='francoismugorozi@gmail.com'
                      />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Edit fontSize='small' color='primary' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary='Phone' secondary='+250781139747' />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Edit fontSize='small' color='primary' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary='Website'
                        secondary='inyarwanda.com'
                      />
                      <ListItemSecondaryAction>
                        <IconButton>
                          <Edit fontSize='small' color='primary' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
};
