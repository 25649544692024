import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logos/logo.png";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.grey[100],
		margin: 0,
		display: "flex",
		alignItems: "center",
		height: "100vh",
		padding: 10,
	},
	container: {
		width: 400,
		margin: "0 auto",
		background: "#fff",
		padding: 20,
		boxShadow:
			"0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 6px 0 rgba(0, 0, 0, 0.19)",
		[theme.breakpoints.down("xs")]: {
			width: "auto",
		},
	},
	logo: {
		margin: "0px auto 20px",
		width: 100,
		height: 100,
		[theme.breakpoints.down("xs")]: {
			width: 80,
			height: 80,
		},
	},
	header: {
		marginBottom: "2vh",
	},
}));

export default ({ children, title }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid className={classes.container}>
				<Grid
					container
					justify="center"
					direction="column"
					className={classes.header}
				>
					<img src={logo} alt="Inyarwanda" className={classes.logo} />
					<Typography variant="h6" align="center">
						{title || "Welcome To Noneho Platform"}
					</Typography>
				</Grid>
				{children}
			</Grid>
		</div>
	);
};
