import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomCard from '../../../components/cards/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckBox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    margin: 0,
  },
}));

export default ({ apps, onCheckTick }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      {apps[0] &&
        apps.map((app) => (
          <Grid item md={6} sm={12} key={app.id}>
            <CustomCard
              body={
                <List>
                  <React.Fragment>
                    <ListItem>
                      <ListItemIcon>
                        <Tooltip
                          title={
                            app.checked ? 'Uncheck to remove' : 'Check to add'
                          }
                          arrow
                        >
                          <CheckBox
                            color='primary'
                            checked={app.checked||false}
                            data-appid={app.id}
                            onClick={onCheckTick}
                          />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={app.name}
                        disableTypography
                        secondary={
                          <Grid>
                            <a href={app.domain}>{app.domain}</a>
                            <Typography variant='body2'>{app.id}</Typography>
                          </Grid>
                        }
                      />
                    </ListItem>
                    {app.checked && (
                      <Box paddingLeft={8}>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            primary='Roles'
                            disableTypography
                            secondary={
                              <List>
                                {app.roles.map((role) => (
                                  <ListItem
                                    className={classes.listItem}
                                    key={role.name}
                                  >
                                    <ListItemIcon>
                                      <CheckBox
                                        color='primary'
                                        size='small'
                                        checked={role.checked||false}
                                        onClick={onCheckTick}
                                        data-appid={app.id}
                                        data-role={role.name}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={role.name} />
                                  </ListItem>
                                ))}
                              </List>
                            }
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            primary='Resources'
                            disableTypography
                            secondary={
                              <List>
                                {app.resources.map((res) => (
                                  <React.Fragment key={res.name}>
                                    <ListItem className={classes.listItem}>
                                      <ListItemIcon>
                                        <CheckBox
                                          color='primary'
                                          size='small'
                                          checked={res.checked||false}
                                          onClick={onCheckTick}
                                          data-appid={app.id}
                                          data-resource={res.name}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={res.name} />
                                    </ListItem>
                                    {res.checked && (
                                      <Box paddingLeft={7}>
                                        <ListItem className={classes.listItem}>
                                          <ListItemText
                                            primary='Permissions'
                                            disableTypography
                                            secondary={
                                              <List>
                                                {res.permissions.map((perm) => (
                                                  <ListItem
                                                    key={perm.name}
                                                    className={classes.listItem}
                                                  >
                                                    <ListItemIcon>
                                                      <CheckBox
                                                        color='primary'
                                                        size='small'
                                                        checked={perm.checked||false}
                                                        onClick={onCheckTick}
                                                        data-appid={app.id}
                                                        data-resource={res.name}
                                                        data-permission={
                                                          perm.name
                                                        }
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                      primary={perm.name}
                                                    />
                                                  </ListItem>
                                                ))}
                                              </List>
                                            }
                                          />
                                        </ListItem>
                                      </Box>
                                    )}
                                  </React.Fragment>
                                ))}
                              </List>
                            }
                          />
                        </ListItem>
                      </Box>
                    )}
                  </React.Fragment>
                </List>
              }
            />
          </Grid>
        ))}
    </Grid>
  );
};
