export default (theme) => ({
  googleButton: {
    background: theme.palette.error.main,
    padding: 10,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.main,
      cursor: 'pointer',
      opacity: 0.98,
    },
    margin: '10px',
  },
  loginButton: {
    background: theme.palette.primary.main,
    padding: 10,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.main,
      cursor: 'pointer',
      opacity: 0.98,
    },
    margin: '20px 10px',
  },
  authIcon: {
    width: 30,
  },
  link: { color: theme.palette.primary.main },
  progress: {
    margin: '8px auto',
  },
});
