import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dangerColor, primaryColor } from '../../assets/styles/base';
import LinearProgress from '@material-ui/core/LinearProgress';

export default (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle
          id='alert-dialog-title'
          style={{ textTransform: 'capitalize' }}>
          {props.title}
          {props.loading ? <LinearProgress variant='indeterminate' /> : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancel}
            color='inherit'
            variant='contained'
            disabled={props.loading}>
            Cancel
          </Button>
          <Button
            disabled={props.loading}
            onClick={props.onConfirm}
            color='primary'
            autoFocus
            style={{
              background: props.danger ? dangerColor[0] : primaryColor[0],
            }}
            variant='contained'>
            {props.confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
