import { auth, googleProvider } from "../config/firebase";

export default class AuthService {
	static extractUserData(userData) {
		return {
			uid: userData.uid,
			email: userData.email,
		};
	}

	static async loginWithGoogle() {
		try {
			googleProvider.setCustomParameters({
				prompt: "select_account",
			});
			const response = await auth.signInWithPopup(googleProvider);
			const user = this.extractUserData(response.user);
			user.isNew = response.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Connection failed, Please check your network or contact your administrator"
						: error.message.replace("signInWithPopup", "")
					: "Something went wrong, we couldn't log you in. Please try again.",
			};
		}
	}

	static async continueWithGoogle(email) {
		try {
			googleProvider.setCustomParameters({
				login_hint: { email },
				prompt: "select_account",
			});
			const response = await auth.signInWithPopup(googleProvider);
			const user = this.extractUserData(response.user);
			if (user.email !== email)
				return {
					error: true,
					message: `Email chosen is not the same as invitation email,please use ${email}`,
				};
			user.isNew = response.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Connection failed, Please check your network or contact your administrator"
						: error.message.replace("signInWithPopup", "")
					: "Something went wrong, we couldn't log you in. Please try again.",
			};
		}
	}

	static async loginWithPassword(email, password) {
		try {
			const response = await auth.signInWithEmailAndPassword(email, password);
			// if (!response.user.emailVerified) {
			//   return {
			//     error: true,
			//     message:
			//       "You haven't verified your e-mail address, Please check the link in your inbox",
			//   };
			// }
			const user = this.extractUserData(response.user);
			user.isNew = response.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Something went wrong, Please check your network or contact your administrator"
						: error.message.replace("signInWithEmailAndPassword", "")
					: "Something went wrong, we couldn't log you in. Please try again.",
			};
		}
	}

	static async signupWithPassword(
		email,
		password,
		firstName,
		lastName,
		redUrl
	) {
		try {
			const createdUser = await auth.createUserWithEmailAndPassword(
				email,
				password
			);
			try {
				await createdUser.user.updateProfile({
					displayName: `${firstName} ${lastName}`,
				});
				await createdUser.user.sendEmailVerification({
					url: redUrl || `https://demand.noneho.com`,
				});
			} catch (error) {}
			const user = this.extractUserData(createdUser.user);
			user.isNew = createdUser.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Something went wrong, Please check your network or contact your administrator"
						: error.message.replace("createUserWithEmailAndPassword", "")
					: "Something went wrong, we couldn't create your account. Please try again.",
			};
		}
	}

	static async continueWithPassword(email, password) {
		try {
			const response = await auth.signInWithEmailAndPassword(email, password);
			const user = this.extractUserData(response.user);
			user.isNew = response.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			if (error.code === "auth/email-already-in-use") {
				try {
					const userLogin = await auth.signInWithEmailAndPassword(
						email,
						password
					);
					const user = await this.extractUserData(userLogin.user);
					return user;
				} catch (error) {
					return {
						error: true,
						message: error.message
							? error.message.includes("network error")
								? "Something went wrong, Please check your network or contact your administrator"
								: error.message.replace("signInWithEmailAndPassword", "")
							: "Something went wrong, we couldn't log you in. Please try again.",
					};
				}
			}
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Something went wrong, Please check your network or contact your administrator"
						: error.message.replace("createUserWithEmailAndPassword", "")
					: "Something went wrong, we couldn't create your account. Please try again.",
			};
		}
	}

	static async loginWithCustomToken(token) {
		try {
			const response = await auth.signInWithCustomToken(token);
			const user = this.extractUserData(response.user);
			user.isNew = response.additionalUserInfo.isNewUser;
			return user;
		} catch (error) {
			console.log(error);
			return {
				error: true,
				message:
					error.code === "auth/invalid-custom-token"
						? "The token provided is inavlid, please login or contact administrator"
						: error.message.includes("network error")
						? "Connection failed, please check your internet"
						: "Something went wrong, please retry or contact administrator",
			};
		}
	}

	static async logout() {
		try {
			await auth.signOut();
			return {
				message: "Signed out successfuly",
			};
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Something went wrong, Please check your network or contact your administrator"
						: error.message.replace("signOut", "")
					: "Something went wrong, we couldn't log you out. Please try again.",
			};
		}
	}

	static async sendPasswordReset(email, returnUrl) {
		try {
			await auth.sendPasswordResetEmail(email, {
				url: returnUrl.startsWith("http")
					? returnUrl
					: `${window.location.protocol}//${window.location.host}${returnUrl}`,
			});
			return {
				message:
					"Password reset link sent to your email, please check your inbox",
			};
		} catch (error) {
			return {
				error: true,
				message: error.message
					? error.message.includes("network error")
						? "Please check your network connectivity or contact your administrator"
						: error.message
					: "Failed sending password reset request. Please try again.",
			};
		}
	}
}
