import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppLayout from '../../../layouts/DashboardLayout';
import UsersContainer from '../../containers/users/UsersContainer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import UserContainer from '../../containers/users/UserContainer';
import InviteUserContainer from '../../containers/users/InviteUserContainer';
import Grid from '@material-ui/core/Grid';

export default (props) => {
  const uid = props.match.params.uid;
  return (
    <AppLayout>
      {uid ? (
        <UserContainer userId={uid} />
      ) : (
        <Grid container justify='center' spacing={2}>
          <Grid item md={8} xs={12}>
            <Typography variant='h5'>Noneho Users</Typography>
            <UsersContainer />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant='h5'>Invite new users</Typography>
            <InviteUserContainer />
          </Grid>
        </Grid>
      )}
    </AppLayout>
  );
};
