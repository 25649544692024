import React from 'react';
import CreateAppContainer from '../../containers/apps/CreateAppContainer';
import AppLayout from '../../../layouts/DashboardLayout';

export default () => {
  return (
    <AppLayout>
      <CreateAppContainer />
    </AppLayout>
  );
};
