import backendService from '../../services/backendService';

export default ({ method, path, data, options, actionType }) => {
  return async (dispatch) =>
    await dispatch({
      type: actionType,
      payload: backendService({
        method,
        path,
        data,
        options,
      }),
    });
};
