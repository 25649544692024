import { FETCH_ALL_USERS, FETCH_SPECIFIC_USERS } from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class AppReducers {
  static allUsersReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ALL_USERS}_PENDING`:
        return { ...state, loading: true };
      case `${FETCH_ALL_USERS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ALL_USERS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static specificUsersReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_SPECIFIC_USERS}_PENDING`:
        return { ...state, loading: true };
      case `${FETCH_SPECIFIC_USERS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_SPECIFIC_USERS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
