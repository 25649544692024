import React, { useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppsCard from './AppsCard';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    margin: 0,
  },
}));

export default ({ open, userName, onCancel, onConfirm, apps }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});

  const cancelModel = () => {
    setState({ apps: [] });
    onCancel();
  };
  const confirm = () => {
    const selected = apps.filter((app) => app.checked);
    onConfirm(selected);
  };

  const onCheckTick = (event) => {
    const dataset = event.currentTarget.dataset;
    const currentApps = apps;
    const pickedApp = currentApps.find((app) => app.id === dataset.appid);
    const pickedAppIndex = currentApps.indexOf();
    if (dataset.resource) {
      const currentResource = pickedApp.resources.findIndex(
        (res) => res.name === dataset.resource
      );
      if (dataset.permission) {
        const currentPermIndex = pickedApp.resources[
          currentResource
        ].permissions.findIndex((perm) => perm.name === dataset.permission);
        pickedApp.resources[currentResource].permissions[
          currentPermIndex
        ].checked = !pickedApp.resources[currentResource].permissions[
          currentPermIndex
        ].checked;
      } else {
        pickedApp.resources[currentResource].checked = !pickedApp.resources[
          currentResource
        ].checked;
      }
    }
    if (dataset.role) {
      const currentRole = pickedApp.roles.findIndex(
        (role) => role.name === dataset.role
      );
      pickedApp.roles[currentRole].checked = !pickedApp.roles[currentRole]
        .checked;
    }

    if (!dataset.role && !dataset.resource && dataset.appid) {
      pickedApp.checked = !pickedApp.checked;
    }

    currentApps[pickedAppIndex] = pickedApp;
    setState({ ...state, apps: currentApps });
  };

  return (
    <Dialog open={open} fullWidth maxWidth='lg'>
      <DialogTitle>Add apps to {userName || 'user'}</DialogTitle>
      <DialogContent>
        <AppsCard apps={apps} onCheckTick={onCheckTick} />
      </DialogContent>
      <DialogActions>
        <Box padding={1}>
          <Button onClick={cancelModel}>Cancel</Button>
          <Button color='primary' onClick={confirm}>
            Ok
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
