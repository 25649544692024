import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardMedia: {
    height: 140,
  },
}));

export default ({ title, body, actions, image }) => {
  const classes = useStyles();
  return (
    <Card>
      {title && <CardHeader title={title} />}
      {image && <CardMedia image={image} className={classes.cardMedia} />}
      <CardContent component='div'>{body}</CardContent>
      <CardActions className={classes.cardActions}>{actions}</CardActions>
    </Card>
  );
};
