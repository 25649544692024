import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiActionCreator from "../../../redux/actions/apiActionCreator";
import { FETCH_ALL_APPS } from "../../../redux/apps/types";
import Alert from "../../../components/alerts/FixedAlert";
import Loading from "../../../components/alerts/Loading";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TableView from "../../../components/tables/TableView";
import ListItemMenu from "../../../components/menu/ListItemMenu";
import InviteAppUser from "../../components/apps/InviteAppUser";
import identityService from "../../../services/backendService";
import Toastify from "../../../components/alerts/Toastify";

export default () => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const allApps = useSelector((store) => store.allApps);
  useEffect(() => {
    dispatch(
      apiActionCreator({
        method: "GET",
        path: "/api/apps",
        actionType: FETCH_ALL_APPS,
      })
    );
  }, []);

  const toggleModel = (action = {}) => {
    setState((prev) => ({ ...action }));
  };

  const headCells = [
    {
      id: "name",
      label: "Name",
      disablePadding: false,
      numeric: false,
    },
    {
      id: "domain",
      label: "Domain",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "orgs",
      label: "Organizations",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "users",
      label: "Users",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "created",
      label: "Created",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "action",
      label: "",
      disablePadding: true,
      numeric: false,
    },
  ];
  const rows = allApps.data.results?.map((app) => ({
    name: app.name,
    domain: app.domain,
    orgs: app.organizations?.length || 0,
    users: app.users?.length || 0,
    created: app.createdAt.substring(0, 10),
    action: (
      <ListItemMenu
        menu={[
          {
            primary: "Details",
            link: `/apps/${app.id}`,
          },
          {
            primary: "Invite User",
            onClick: () =>
              toggleModel({
                addUser: true,
                appName: app.name,
                appId: app.id,
                model: true,
                appRoles: app.roles,
              }),
          },
          {
            primary: "Visit website",
            onClick: () => window.open(app.domain, "_blank"),
          },
        ]}
      />
    ),
  }));

  const onInviteUser = async (inviteData) => {
    console.log(inviteData);
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      path: `/api/apps/${state.appId}/users`,
      method: "POST",
      data: {
        name: inviteData.name,
        email: inviteData.email,
        role: inviteData.role,
      },
    });
    Toastify({
      type: response.error ? "error" : "success",
      message: response.message,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          toggleModel();
          dispatch(
            apiActionCreator({
              method: "GET",
              path: "/api/apps",
              actionType: FETCH_ALL_APPS,
            })
          );
        }
      },
    });
  };

  return allApps.loading ? (
    <Loading />
  ) : allApps.data.error ? (
    <Alert title={allApps.data.message} type="warning" />
  ) : !allApps.data.results || !allApps.data.results[0] ? (
    <Alert title="No apps found" />
  ) : (
    <React.Fragment>
      <TableView
        title="Noneho Applications"
        headCells={headCells}
        rows={rows}
        dense
      />
      {state.addUser && (
        <InviteAppUser
          onCancel={() => toggleModel()}
          onConfirm={onInviteUser}
          open={state.model}
          appName={state.appName}
          roles={state.appRoles}
          loading={state.loading}
        />
      )}
    </React.Fragment>
  );
};
