import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubHeader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Tooltip } from "@material-ui/core";
import ListItemMenu from "../../../components/menu/ListItemMenu";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import CustomDialog from "../../../components/Dialogs/CustomDialog";
import TextField from "../../../components/CustomInput/TextField";
import CustomSelect from "../../../components/CustomSelect/SimpleCustomSelect";
import moment from "moment";
import componentAllowed from "../../../utils/componentAllowed";
import ConfirmModel from "../../../components/Dialogs/ConfirmationDialog";
import SingleItemMenu from "../../../components/menu/SingleItemMenu";
import ProgressiveImage from "react-progressive-image";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import defaultImg from "../../../assets/images/placeholder.png";
import { Add, Language } from "@material-ui/icons";
import TableView from "../../../components/tables/TableView";

const styles = makeStyles((theme) => itemInfoStyles(theme));

export default ({
  app,
  onAddUser,
  onRemoveUser,
  onChangeUserRole,
  loading,
}) => {
  const classes = styles();
  const [state, setState] = React.useState({ model: false });
  const handleUserRoleChange = (selectedOption) => {
    setState({ ...state, selectedRole: selectedOption.value });
  };
  const onEmailChange = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const addUserComponent = () => {
    return (
      <Box>
        <TextField
          label="Name"
          fullWidth
          name="name"
          onChange={onEmailChange}
        />
        <br></br>
        <TextField
          label="Email"
          fullWidth
          type="email"
          name="email"
          onChange={onEmailChange}
        />
        <br></br>
        <CustomSelect
          onInputChange={() => {}}
          onChange={handleUserRoleChange}
          options={
            (app.roles &&
              app.roles.map((role) => ({ label: role, value: role }))) ||
            []
          }
          value={state.selectedRole || "Select Role"}
        />
      </Box>
    );
  };

  const changeUserRoleComponent = () => {
    return (
      <Box>
        <TextField label="Name" fullWidth value={state.selectedName} disabled />
        <br></br>
        <TextField
          label="Email"
          fullWidth
          type="email"
          value={state.selectedEmail}
          disabled
        />
        <br></br>
        <CustomSelect
          onInputChange={() => {}}
          onChange={handleUserRoleChange}
          options={
            (app.roles &&
              app.roles.map((role) => ({ label: role, value: role }))) ||
            []
          }
          value={state.selectedRole || "Select Role"}
        />
      </Box>
    );
  };

  const handlePublisherSelected = (publisher) => {
    setState({
      ...state,
      publisher: publisher,
    });
  };

  const addPublisherComponent = () => {
    return (
      <Box>
        <CustomSelect
          onInputChange={() => {}}
          onChange={handlePublisherSelected}
          options={
            (publishers &&
              publishers.map((publisher) => ({
                label: publisher.name,
                value: publisher.id,
              }))) ||
            []
          }
          value={
            (state.publisher && state.publisher.label) || "Select Publisher"
          }
        />
      </Box>
    );
  };

  const oncConfirmModelOk = () => {
    onRemoveUser(state.uid, state.email, state.name);
    setState({
      ...state,
      confirmModel: false,
    });
  };

  const toggleModel = (action) => {
    if (action.changeRole)
      setState({
        ...state,
        selectedRole: null,
        model: !state.model,
        selectedEmail: action.email,
        selectedName: action.name,
        changeRole: action.changeRole,
      });
    else if (action.addPublisher) {
      setState({
        ...state,
        model: !state.model,
        addPublisher: true,
      });
    } else if (action.removeUser) {
      setState({
        ...state,
        uid: action.uid,
        email: action.email,
        name: action.name,
        confirmModel: true,
        confirmTitle: "Confirm removing user",
        confirmMessage: `Are sure you want to remove ${action.email} from ${
          app.appName || app.name
        }`,
        danger: true,
        confirmText: "Remove",
      });
    } else if (action.addUser) {
      setState({
        ...state,
        model: !state.model,
      });
    } else
      setState({
        ...state,
        selectedRole: null,
        selectedEmail: null,
        selectedName: null,
        model: false,
        changedUser: null,
        addPublisher: false,
        confirmModel: false,
      });
  };

  const onConfirm = () => {
    if (state.changeRole)
      onChangeUserRole(
        state.selectedRole,
        state.selectedEmail,
        state.selectedName
      );
    else if (state.addPublisher) onAddPublisher(state.publisher);
    else onAddUser(state.name, state.email, state.selectedRole);
    // toggleModel({});
  };

  const actions = componentAllowed(["owner"], "org")
    ? [
        {
          onClick: () => toggleModel({ addUser: true }),
          text: "Add User",
          icon: <Add fontSize="small" />,
        },
        {
          onClick: () => console.log("Editing"),
          text: "Edit",
          icon: <Edit fontSize="small" />,
        },
        {
          onClick: () => window.open(app.domain, "_blank"),
          text: "Visit Website",
          icon: <Language fontSize="small" />,
        },
      ]
    : [
        {
          onClick: () => console.log("Editing"),
          text: "Edit",
          icon: <Edit fontSize="small" />,
        },
        {
          onClick: () => window.open(app.domain, "_blank"),
          text: "Visit Website",
          icon: <Language fontSize="small" />,
        },
      ];

  const headCells = [
    {
      id: "name",
      label: "Name",
      disablePadding: false,
      numeric: false,
    },
    {
      id: "email",
      label: "email",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "role",
      label: "Role",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "action",
      label: "",
      disablePadding: true,
      numeric: false,
    },
  ];

  const rows = app.users?.map((user) => ({
    name: user.name,
    email: user.email,
    role: user.role,
    action: (
      <ListItemMenu
        menu={[
          {
            primary: "Change role",
            onClick: () =>
              toggleModel({
                ...user,
                changeRole: true,
              }),
            hidden: !componentAllowed(["owner", "admin"], "org"),
          },
          {
            primary: "Remove user",
            onClick: () =>
              toggleModel({
                ...user,
                removeUser: true,
              }),
            hidden: !componentAllowed(["owner", "admin"], "org"),
          },
        ]}
      />
    ),
  }));

  return (
    <div>
      <Grid container className={classes.topSection}>
        <Grid item xs={12} container justify="flex-start">
          <div>
            <ProgressiveImage
              src={app.logoSrc}
              placeholder={defaultImg}
              key={app.name || app.appName}
            >
              {(src, loading) => (
                <img
                  style={{
                    opacity: loading ? 0.7 : 1,
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                  src={src}
                  alt="No cover"
                  className={classes.image}
                />
              )}
            </ProgressiveImage>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={2}
          className={classes.infoSection}
        >
          <Grid item className={classes.textInfo}>
            <Typography variant="h4" className={classes.texts}>
              {app.name || app.appName}
            </Typography>
            <Grid item className={classes.actions}>
              <Grid container spacing={2} justify="flex-end">
                <SingleItemMenu horiz background menu={actions} />
              </Grid>
            </Grid>

            <Tooltip
              title="Time item was created"
              placement="bottom-start"
              arrow
            >
              <Typography variant="h6" className={classes.name}>
                Created: {moment(app.createdAt).fromNow()}
              </Typography>
            </Tooltip>

            <Typography className={classes.texts} variant="body1">
              Domain: {app.domain || "unset"}
            </Typography>
            <Typography className={classes.texts} variant="body1">
              Details: {app.description || "unset"}
            </Typography>
            <Typography className={classes.texts} variant="body1">
              Users: {app.users ? app.users.length : 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <br />

      <Grid item sm={12} xs={12}>
        <Box>
          <TableView
            title="Application users"
            headCells={headCells}
            rows={rows || []}
            dense
          />
          <ConfirmModel
            open={state.confirmModel || false}
            title={state.confirmTitle}
            message={state.confirmMessage}
            confirmText={state.confirmText}
            danger={state.danger}
            onCancel={() => toggleModel({ model: false })}
            onConfirm={oncConfirmModelOk}
            loading={loading}
          />
        </Box>
      </Grid>

      <CustomDialog
        title={
          state.changeRole
            ? "Change user role"
            : state.addPublisher
            ? "Add app publisher"
            : `Add user to ${app.name || app.appName}`
        }
        okText={
          state.changeRole ? "Apply" : state.addPublisher ? "Add" : "Send"
        }
        open={state.model}
        fullWidth
        content={
          state.changeRole
            ? changeUserRoleComponent()
            : state.addPublisher
            ? addPublisherComponent()
            : addUserComponent()
        }
        onCancel={toggleModel}
        onConfirm={onConfirm}
        loading={loading}
      />
    </div>
  );
};
