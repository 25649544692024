import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import backendService from "../services/backendService";
import { Typography } from "@material-ui/core";
import AuthService from "../services/authService";
import { auth } from "../config/firebase";
import Alert from "../components/alerts/FixedAlert";
import currentUserClaims from "../utils/currentUserClaims";
export default class AuthProvider extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		const handleAuthCheck = async () => {
			const resp = await backendService({
				path: "/auth/status",
				method: "GET",
			});
			if (resp.error && resp.status === 401) {
				// window.location.assign(
				//   `${process.env.IDENTITY_UI_URL}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
				// );
			} else {
				if (!localStorage.getItem("au")) {
					const user = await AuthService.loginWithCustomToken(
						resp.results.customToken
					);
					if (user.error) {
						this.setState({ error: user.message, loading: false });
					}
				}
			}
		};
		handleAuthCheck();
		this.onAuthChange = auth.onAuthStateChanged(async (currentUser) => {
			if (currentUser) {
				try {
					const userData = await currentUser.getIdTokenResult(true);
					localStorage.setItem(
						"au",
						JSON.stringify({
							displayName: currentUser.displayName,
							email: currentUser.email,
							photo: currentUser.photoURL,
							claims: {
								...userData.claims,
								...currentUserClaims(userData.claims, process.env.APP_ID),
								aud: undefined,
								auth_time: undefined,
								email: undefined,
								email_verified: undefined,
								exp: undefined,
								firebase: undefined,
								iat: undefined,
								iss: undefined,
								picture: undefined,
								sub: undefined,
								user_id: undefined,
								name: undefined,
								apps: undefined,
								organizations: undefined,
							},
							atkn: userData.token,
						})
					);
					this.setState({ loading: false });
				} catch (error) {
					console.log(error);
					this.setState({ loading: false });
				}
			} else {
				localStorage.removeItem("au");
				window.location.assign(
					`${process.env.IDENTITY_UI_URL}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
				);
			}
		});

		setInterval(async () => {
			const token = await auth.currentUser.getIdToken(true);
			const currentUser = JSON.parse(localStorage.getItem("au"));
			currentUser.idToken = token;
			localStorage.setItem("au", JSON.stringify(currentUser));
		}, 58 * 60 * 1000);
	}

	componentWillUnmount() {
		this.onAuthChange();
	}
	render() {
		return (
			<div>
				{this.state.loading ? (
					<Box padding={2}>
						<Grid
							container
							justify="center"
							alignItems="center"
							direction="column"
							style={{ margin: "42vh 0" }}
						>
							<CircularProgress />
							<Typography align="center">
								Dear user, please wait a moment...
							</Typography>
						</Grid>
					</Box>
				) : this.state.error ? (
					<Box padding={2}>
						<Grid
							container
							justify="center"
							alignItems="center"
							direction="column"
							style={{ margin: "42vh 0" }}
						>
							<Alert title={this.state.error} type="warning" />
						</Grid>
					</Box>
				) : (
					this.props.children
				)}
			</div>
		);
	}
}
