import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UsersListComponent from "../../components/users/UsersListComponent";
import apiActionCreator from "../../../redux/actions/apiActionCreator";
import { FETCH_ALL_USERS } from "../../../redux/users/types";
import Alert from "../../../components/alerts/FixedAlert";
import Loading from "../../../components/alerts/Loading";
import TableView from "../../../components/tables/TableView";
import ListItemMenu from "../../../components/menu/ListItemMenu";

export default () => {
  const dispatch = useDispatch();
  const allUsers = useSelector((store) => store.allUsers);
  useEffect(() => {
    dispatch(
      apiActionCreator({
        method: "GET",
        path: "/api/users",
        actionType: FETCH_ALL_USERS,
      })
    );
  }, []);

  const headCells = [
    {
      id: "name",
      label: "Name",
      disablePadding: false,
      numeric: false,
    },
    {
      id: "email",
      label: "email",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "phone",
      label: "Phone Number",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "created",
      label: "Created",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "action",
      label: "",
      disablePadding: true,
      numeric: false,
    },
  ];

  const rows = allUsers.data.results?.map((user) => ({
    name: user.name||user.displayName,
    email: user.email,
    phone: user.phoneNumber,
    created: user.createdAt,
    action: (
      <ListItemMenu
        menu={[
          {
            primary: "View User",
            onClick: () => console.log("Invite app user"),
          },
          {
            primary: "Delete User",
            onClick: () => console.log("delete app"),
          },
        ]}
      />
    ),
  }));

  return allUsers.loading ? (
    <Loading />
  ) : allUsers.data.error ? (
    <Alert title={allUsers.data.message} type="warning" />
  ) : !allUsers.data.results || !allUsers.data.results[0] ? (
    <Alert title="No users found" />
  ) : (
    <TableView title="Noneho Users" headCells={headCells} rows={rows} dense />
  );
};
