import React from 'react';
import AppLayout from '../../../layouts/DashboardLayout';
import OrgsContainer from '../../containers/orgs/OrgsContainer';

export default () => {
  return (
    <AppLayout>
      <OrgsContainer />
    </AppLayout>
  );
};
