import React from "react";
import AppLayout from "../../../layouts/DashboardLayout";
import AppsContainer from "../../containers/apps/AllApps";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import AppContainer from "../../containers/apps/AppContainer";

export default (props) => {
  const appId = props.match.params.appId;
  return (
    <AppLayout>
      <Grid item container justify="flex-end">
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/apps/create"
        >
          Add App
        </Button>
      </Grid>
      {appId ? <AppContainer appId={appId} /> : <AppsContainer />}
    </AppLayout>
  );
};
