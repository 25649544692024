import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import appTheme from './layouts/theme';
import { AppRoutes, AuthRoutes } from './routes/index';
import createStore from './redux';
import AuthProvider from './providers/AuthProvider';

const store = createStore();

const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <AuthRoutes />
      {window.location.pathname !== '/login' &&
        window.location.pathname !== '/signup'&&window.location.pathname !== '/invite/accept' && (
          <Provider store={store}>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </Provider>
        )}
    </ThemeProvider>
  );
};

export default App;
