import { FETCH_ALL_APPS, GET_APP_INFO } from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class AppReducers {
  static allAppsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ALL_APPS}_PENDING`:
        return { ...state, loading: true };
      case `${FETCH_ALL_APPS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ALL_APPS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static appReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_APP_INFO}_PENDING`:
        return { ...state, loading: true };
      case `${GET_APP_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_APP_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
