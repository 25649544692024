import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={props.type}>
        <AlertTitle>{props.title || props.type.toUpperCase()}</AlertTitle>
        <strong>{props.messageFocus || null}</strong> {props.message}
      </Alert>
    </div>
  );
};
