// @material-ui/icons
import Artists from '@material-ui/icons/SupervisorAccount';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import BusinessIcon from '@material-ui/icons/Business';

var dashRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: DashboardIcon,
    page: 'dashboard',
  },
  {
    path: '/apps',
    name: 'Applications',
    icon: AppsIcon,
    page: 'applications',
  },
  // {
  //   path: '/orgs',
  //   name: 'Organizations',
  //   icon: BusinessIcon,
  //   page: 'organizations',
  // },
  // {
  //   path: '/users',
  //   name: 'Users',
  //   icon: Artists,
  //   page: 'users',
  // },
];

export default dashRoutes;
