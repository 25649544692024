export default (userClaims, appId, orgId) => {
  const { apps = [], organizations = [] } = userClaims || {};

  const userApp = apps.find((app) => app.appId === appId) || {};
  const currentOrg =
    organizations.find(
      (or) => or.apps && or.apps.findIndex((a) => a.appId === appId) > -1
    ) || {};
  const orgApp = currentOrg.apps
    ? currentOrg.apps.find((a) => a.appId === appId) || {}
    : {};

  return {
    userApp,
    userOrg: {
      orgId,
      role: orgApp.role || userApp.role,
    },
  };
};
