import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OrgsComponent from "../../components/orgs/AllOrganizations";
import actionCreator from "../../../redux/actions/apiActionCreator";
import { FETCH_ALL_ORGS } from "../../../redux/orgs/types";
import backendService from "../../../services/backendService";
import Toast from "../../../components/alerts/Toastify";
import TableView from "../../../components/tables/TableView";
import ListItemMenu from "../../../components/menu/ListItemMenu";
import Alert from "../../../components/alerts/FixedAlert";
import Loading from "../../../components/alerts/Loading";

export default () => {
  const orgs = useSelector((store) => store.allOrgs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actionCreator({
        actionType: FETCH_ALL_ORGS,
        path: "/api/orgs",
        method: "GET",
      })
    );
  }, []);

  const onOrgCreate = async (orgName) => {
    const response = await backendService({
      method: "POST",
      path: "/api/orgs",
      data: {
        name: orgName,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        dispatch(
          actionCreator({
            actionType: FETCH_ALL_ORGS,
            path: "/api/orgs",
            method: "GET",
          })
        );
      },
    });
  };

  const headCells = [
    {
      id: "name",
      label: "Name",
      disablePadding: false,
      numeric: false,
    },
    {
      id: "website",
      label: "website",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "email",
      label: "email",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "phone",
      label: "Phone Number",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "users",
      label: "Users",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "created",
      label: "Created",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "action",
      label: "",
      disablePadding: true,
      numeric: false,
    },
  ];

  const rows = orgs.data.results?.map((org) => ({
    name: org.name,
    website: org.website,
    email: org.email,
    phone: org.phoneNumber,
    users: org.users?.length || 0,
    created: org.createdAt.substring(0, 10),
    action: (
      <ListItemMenu
        menu={[
          {
            primary: "View Organization",
            onClick: () => console.log("Invite app user"),
          },
          {
            primary: "Invite User",
            onClick: () => console.log("Invite app user"),
          },
          {
            primary: "Delete Organization",
            onClick: () => console.log("delete app"),
          },
        ]}
      />
    ),
  }));

  return orgs.loading ? (
    <Loading />
  ) : orgs.data.error ? (
    <Alert title={orgs.data.message} type="warning" />
  ) : !orgs.data.results || !orgs.data.results[0] ? (
    <Alert title="No organizations found" />
  ) : (
    <TableView
      title="Noneho Organizations"
      headCells={headCells}
      rows={rows}
      dense
    />
  );
};
