import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DashboardPage from "../views/pages/Dashboard";
import AppsPage from "../views/pages/apps/AppsPage";
import UsersPage from "../views/pages/users/UsersPage";
import UserAccoutPage from "../views/pages/users/UserAccount";
import CreateAppPage from "../views/pages/apps/CreateAppPage";
import OrgsPage from "../views/pages/orgs/OrgsPage";

export default () => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/" component={DashboardPage} />
          <Route exact path="/apps" component={AppsPage} />
          <Route exact path="/apps/create" component={CreateAppPage} />
          <Route exact path="/apps/:appId" component={AppsPage} />
          <Route exact path="/users" component={UsersPage} />
          <Route exact path="/users/:uid" component={UsersPage} />
          <Route exact path="/account" component={UserAccoutPage} />
          <Route exact path="/orgs" component={OrgsPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};
