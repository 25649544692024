import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { appsReducers } from './apps/index';
import { usersReducers } from './users/index';
import { apiReducers } from './api/index';
import { orgsReducers } from './orgs/index';

const reducers = combineReducers({
  ...appsReducers,
  ...usersReducers,
  ...apiReducers,
  ...orgsReducers,
});

export default () => {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, promise))
  );

  return store;
};
