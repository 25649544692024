import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp(JSON.parse(process.env.FIREBASE_CONFIG));
const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");

const auth = firebase.auth();
export { auth, googleProvider };
