import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../components/Inputs/TextField";
import CreatableSelectInput from "../../../components/Inputs/CreatableSelectInput";
import CUstomCard from "../../../components/cards/CustomCard";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

export default ({ onAppCreate, loading }) => {
  const [state, setState] = React.useState({
    resources: [],
    roles: [],
    resPermissions: [],
  });

  const onTextInput = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const onRoleChange = (newValue) => {
    setState({ ...state, roles: newValue.map((v) => v.value) });
  };
  const onResPermChange = (newValue) => {
    setState({ ...state, resPermissions: newValue.map((v) => v.value) });
  };

  const onAddResource = () => {
    const res = {
      name: state.resName,
      permissions: state.resPermissions,
    };
    const newRes = state.resources;
    newRes.push(res);
    setState({ ...state, resources: newRes });
  };

  const onRemoveResource = (name) => {
    const newRes = state.resources.filter((res) => res.name !== name);
    setState({ ...state, resources: newRes });
  };

  const onCreate = () => {
    const app = {
      name: state.appName,
      domain: state.appUrl,
      roles: state.roles,
      description: state.description,
      // organizations: [],
      // users: [],
      // resources: state.resources,
    };
    onAppCreate(app);
  };

  const AddResource = (
    <Grid container direction="column" spacing={2} justify="center">
      <Grid item>
        <TextField
          id="resName"
          name="resName"
          label="Resource Name"
          fullWidth
          onChange={onTextInput}
        />
      </Grid>
      <Grid item>
        <CreatableSelectInput
          value="Permissions"
          options={[
            { label: "Read", value: "read" },
            { label: "Edit", value: "edit" },
            { label: "Delete", value: "delete" },
          ]}
          isMulti
          onChange={onResPermChange}
        />
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="sm">
      <CUstomCard
        body={
          <React.Fragment>
            <Typography variant="h6">Create app</Typography>
            <Grid container direction="column" spacing={2} justify="center">
              <Grid item>
                <TextField
                  id="appName"
                  label="Name"
                  name="appName"
                  fullWidth
                  onChange={onTextInput}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="appUrl"
                  label="Domain"
                  name="appUrl"
                  fullWidth
                  type="url"
                  onChange={onTextInput}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="description"
                  label="Description"
                  name="description"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={onTextInput}
                />
              </Grid>
              <Grid item>
                <CreatableSelectInput
                  value="Add Roles"
                  options={[{ label: "Admin", value: "admin" }]}
                  isMulti
                  onChange={onRoleChange}
                />
              </Grid>
              {/* <Grid item>
                <List>
                  <ListItem>
                    <ListItemText primary='Resources' />
                  </ListItem>
                  {state.resources.map((res, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        disableTypography
                        primary={res.name}
                        secondary={
                          <React.Fragment>
                            <Typography variant='body1'>
                              Permissions:
                            </Typography>
                            <Typography variant='body2'>
                              {res.permissions.join(',')}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => onRemoveResource(res.name)}>
                          <Delete fontSize='small' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  <ListItem>{AddResource}</ListItem>
                  <ListItem>
                    <ListItemSecondaryAction>
                      <Button color='primary' onClick={onAddResource}>
                        Add
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid> */}
            </Grid>
            <Grid container justify="center" style={{ marginTop: "10px" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={onCreate}
                disabled={loading}
              >
                {loading ? "Loading" : "Create"}
              </Button>
            </Grid>
          </React.Fragment>
        }
      />
      <Box marginBottom={6}></Box>
    </Container>
  );
};
