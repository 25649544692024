import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '../../../components/Inputs/TextField';
import SelectInput from '../../../components/Inputs/SelectInput';
import CreatableSelectInput from '../../../components/Inputs/CreatableSelectInput';
import CUstomCard from '../../../components/cards/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

export default ({ apps, orgs, onInviteUser }) => {
  const appOptions = apps.map((app) => ({
    label: app.name,
    value: app.id,
    roles: app.roles,
    domain: app.url,
  }));
  const orgOptions = orgs.map((org) => ({
    label: org.name,
    value: org.id,
  }));
  const [state, setState] = React.useState({
    selectedApp: null,
    selectedRole: null,
    rolesOptions: [],
  });
  const onAppChange = (newValue) => {
    setState({
      ...state,
      selectedApp: newValue.value,
      appDomain: newValue.domain,
      rolesOptions: newValue.roles.map((role) => ({
        label: role,
        value: role,
      })),
    });
  };

  const onRoleChange = (newValue) => {
    setState({ ...state, selectedRole: newValue.value });
  };
  const onBusinessChange = (newValue) => {
    setState({ ...state, selectedBusiness: newValue.value });
  };

  const onTextInputChange = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const onSend = () => {
    const invite = {
      email: state.email,
      name: state.name,
      claims: {
        app: state.selectedApp &&
          !state.selectedBusiness && {
            appId: state.selectedApp,
            role: state.selectedRole,
          },
        organization: state.selectedBusiness && {
          orgId: state.selectedBusiness,
          role: 'owner',
        },
      },
      invfor:state.selectedBusiness?'org':'app',
      appDomain: state.appDomain,
    };
    onInviteUser(invite);
  };
  return (
    <Box>
      <CUstomCard
        body={
          <React.Fragment>
            <Grid container direction='column' spacing={2} justify='center'>
              <Grid item>
                <TextField
                  id='name'
                  label='User Name'
                  name='name'
                  fullWidth
                  onChange={onTextInputChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  id='email'
                  label='Email'
                  name='email'
                  fullWidth
                  type='email'
                  onChange={onTextInputChange}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  value='Add App'
                  options={appOptions}
                  onChange={onAppChange}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  value='Add Roles'
                  options={state.rolesOptions}
                  onChange={onRoleChange}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  value='Select Business'
                  options={orgOptions}
                  onChange={onBusinessChange}
                />
              </Grid>
              <Grid item container justify='flex-end'>
                <Button color='primary' variant='contained' onClick={onSend}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      />
    </Box>
  );
};
