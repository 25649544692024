import React, { useState } from 'react';
import AcceptInviteComponent from '../../components/auth/AcceptInviteComponent';
import AuthService from '../../../services/authService';
import backendService from '../../../services/backendService';
import AccountPropmptComponent from '../../components/auth/CreateOrgPromptComponent';

export default () => {
  const [state, setState] = useState({
    authState: {},
  });
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const invToken = urlParams.get('invtkn');
  const redUrl = urlParams.get('redUrl');

  const authHandler = async (response) => {
    if (response.error) {
      setState({
        ...state,
        authState: {
          ...response,
          loading: false,
          error: true,
        },
      });
    } else {
      const serverResponse = await backendService({
        path: '/auth/invite/accept',
        method: 'PATCH',
        data: { user: response, invToken, appDomain: redUrl },
      });
      if (serverResponse.error)
        setState({
          ...state,
          authState: {
            message: serverResponse.message,
            loading: false,
            error: true,
          },
        });
      else {
        window.location.href = redUrl || '/';
      }
    }
  };

  const loginWithGoogle = async () => {
    setState({
      ...state,
      authState: {
        loading: true,
      },
    });
    const response = await AuthService.continueWithGoogle(email);
    authHandler(response);
  };

  const loginWithPassword = async (email,password) => {
    setState({
      ...state,
      authState: {
        loading: true,
      },
    });
    const response = await AuthService.continueWithPassword(email, password);
    authHandler(response);
  };

  return (
    <AcceptInviteComponent
      loginWithGoogle={loginWithGoogle}
      authState={state.authState}
      loginWithPassword={loginWithPassword}
      email={email}
    />
  );
};
