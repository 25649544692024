import React from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import AcceptInviteContainer from '../containers/auth/AcceptInviteContainer';

export default () => {
  return (
    <AuthLayout>
      <AcceptInviteContainer />
    </AuthLayout>
  );
};
