import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserComponent from '../../components/users/UserComponent';
import apiActionCreator from '../../../redux/actions/apiActionCreator';
import { FETCH_SPECIFIC_USERS } from '../../../redux/users/types';
import { FETCH_ALL_APPS } from '../../../redux/apps/types';
import { API_CALL } from '../../../redux/api/types';
import Alert from '../../../components/alerts/FixedAlert';
import Loading from '../../../components/alerts/Loading';
import Toast from '../../../components/alerts/Toastify';

export default ({ userId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      apiActionCreator({
        method: 'GET',
        path: `/api/users/${userId}`,
        actionType: FETCH_SPECIFIC_USERS,
      })
    );
    dispatch(
      apiActionCreator({
        method: 'GET',
        path: `/api/apps`,
        actionType: FETCH_ALL_APPS,
      })
    );
  }, []);
  const user = useSelector((store) => store.specificUser);
  const allApps = useSelector((store) => store.allApps);
  const apiCall = useSelector((store) => store.apiCall);

  const [userApps, setUserApps] = React.useState([]);
  const [apps, setApps] = React.useState([]);
  const [state, setState] = React.useState({
    changed: false,
  });

  useEffect(() => {
    const userApps =
      (user.data.results && user.data.results.customClaims.apps) || [];
    userApps.forEach((app) => {
      app.checked = true;
      app.roles = app.roles.map((role) => ({ name: role, checked: true }));
      app.resources = app.resources.map((res) => ({
        name: res.name,
        checked: true,
        permissions: res.permissions.map((perm) => ({
          name: perm,
          checked: true,
        })),
      }));
    });

    setUserApps(userApps.filter((app) => !apps.find((el) => el.id === app.id)));
  }, [user.data.results]);

  useEffect(() => {
    const apps = allApps.data.results || [];
    apps.forEach((app) => {
      app.checked = false;
      app.roles = app.roles.map((role) => ({ name: role, checked: false }));
      app.resources = app.resources.map((res) => ({
        name: res.name,
        checked: false,
        permissions: res.permissions.map((perm) => ({
          name: perm,
          checked: false,
        })),
      }));
    });

    setApps(apps.filter((app) => !userApps.find((el) => el.id === app.id)));
  }, [allApps.data.results]);

  const onCheckTick = (event) => {
    const dataset = event.currentTarget.dataset;
    const currentApps = userApps;
    const pickedApp = currentApps.find((app) => app.id === dataset.appid);
    const pickedAppIndex = currentApps.indexOf();
    if (dataset.resource) {
      const currentResource = pickedApp.resources.findIndex(
        (res) => res.name === dataset.resource
      );
      if (dataset.permission) {
        const currentPermIndex = pickedApp.resources[
          currentResource
        ].permissions.findIndex((perm) => perm.name === dataset.permission);
        pickedApp.resources[currentResource].permissions[
          currentPermIndex
        ].checked = !pickedApp.resources[currentResource].permissions[
          currentPermIndex
        ].checked;
      } else {
        pickedApp.resources[currentResource].checked = !pickedApp.resources[
          currentResource
        ].checked;
      }
    }
    if (dataset.role) {
      const currentRole = pickedApp.roles.findIndex(
        (role) => role.name === dataset.role
      );
      pickedApp.roles[currentRole].checked = !pickedApp.roles[currentRole]
        .checked;
    }

    if (!dataset.role && !dataset.resource && dataset.appid) {
      pickedApp.checked = !pickedApp.checked;
    }

    currentApps[pickedAppIndex] = pickedApp;
    setUserApps(currentApps);
    setState({ changed: true });
  };

  const onConfirm = (selectedApp) => {
    const currentUserApp = userApps.concat(selectedApp);
    setUserApps(currentUserApp);
    setState({ changed: true });
  };

  const onSaveChange = () => {
    const userClaims = user.data.results.customClaims;
    const currentApps = userApps.filter((el) => el.checked);
    currentApps.forEach((app) => {
      app.checked = undefined;
      app.roles = app.roles.filter((r) => r.checked).map((role) => role.name);
      app.resources = app.resources
        .filter((resc) => resc.checked)
        .map((res) => ({
          name: res.name,
          checked: undefined,
          permissions: res.permissions
            .filter((p) => p.checked)
            .map((perm) => perm.name),
        }));
    });
    userClaims.apps = currentApps;
    dispatch(
      apiActionCreator({
        method: 'PATCH',
        path: `/api/users/${userId}`,
        actionType: API_CALL,
        data: { customClaims: userClaims },
      })
    );
  };

  useEffect(() => {
    Toast({
      message: apiCall.message || apiCall.data.message,
      type: apiCall.error ? 'error' : 'success',
      onClose: () => {
        setState({ ...state, changed: false });
        dispatch(
          apiActionCreator({
            method: 'GET',
            path: `/api/users/${userId}`,
            actionType: FETCH_SPECIFIC_USERS,
          })
        );
      },
    });
  }, [apiCall]);

  return user.loading ? (
    <Loading />
  ) : user.data.error ? (
    <Alert title={user.data.message} type='warning' />
  ) : (
    <UserComponent
      user={user.data.results || {}}
      userApps={userApps}
      apps={apps}
      onConfirm={onConfirm}
      changed={state.changed}
      onSaveChange={onSaveChange}
      saving={apiCall.loading}
      onCheckTick={onCheckTick}
    />
  );
};
