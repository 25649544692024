import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default ({ message, position, duration, type, onClose, progress }) => {
  return toast(message, {
    position: position || 'top-right',
    autoClose: duration || 5000,
    hideProgressBar: !progress || true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true,
    type: type,
    onClose: onClose,
  });
};
