import axios from "axios";

const httpInstance = axios.create({
  baseURL: `${process.env.API_BASE_URL}`,
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
    appId: process.env.APP_ID,
  },
  withCredentials: true,
});

httpInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("au"));
    if (user) {
      config.headers.authorization = user.atkn;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default httpInstance;
