import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_APP_INFO } from "../../../redux/apps/types";
import identityAction from "../../../redux/actions/apiActionCreator";
import Loading from "../../../components/alerts/Loading";
import identityService from "../../../services/backendService";
import Toast from "../../../components/alerts/Toastify";
import AppComponent from "../../components/apps/AppComponent";

export default ({ appId }) => {
  const dispatch = useDispatch();
  const appInfo = useSelector((store) => store.appInfo);
  const [state, setState] = useState({});

  useEffect(() => {
    if (!appInfo.data.results)
      dispatch(
        identityAction({
          method: "GET",
          path: `/api/apps/${appId}`,
          actionType: GET_APP_INFO,
        })
      );
  }, []);

  const addUserToApp = async (name, email, role) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "POST",
      path: `/api/apps/${appId}/users`,
      data: {
        name: name || "",
        email: email,
        role,
        appId: process.env.APP_ID,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          dispatch(
            identityAction({
              method: "GET",
              path: `/api/apps/${appId}`,
              actionType: GET_APP_INFO,
            })
          );
        }
      },
    });
  };

  const onRemoveUser = async (uid, email, name) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "DELETE",
      path: `/api/apps/${appId}/users/${uid}`,
      options: {
        data: {
          email,
          name,
          appId: process.env.APP_ID,
        },
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error)
          dispatch(
            identityAction({
              method: "GET",
              path: `api/apps/${appId}`,
              actionType: GET_APP_INFO,
            })
          );
      },
    });
  };

  const onChangeUserRole = async (role, email, name) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "POST",
      path: `/api/apps/${appId}/users`,
      data: {
        role,
        email,
        name,
        appId: process.env.APP_ID,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error)
          dispatch(
            identityAction({
              method: "GET",
              path: `api/apps/${appId}`,
              actionType: GET_APP_INFO,
            })
          );
      },
    });
  };

  return appInfo.loading ? (
    <Loading />
  ) : (
    <AppComponent
      app={appInfo.data.results || {}}
      onAddUser={addUserToApp}
      onRemoveUser={onRemoveUser}
      onChangeUserRole={onChangeUserRole}
      loading={state.loading}
    />
  );
};
