import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import VerticalCard from '../../../components/cards/VerticalCard';
import CustomCard from '../../../components/cards/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubHeader from '@material-ui/core/ListSubheader';
import CheckBox from '@material-ui/core/Checkbox';
import ListSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddAppDialog from './AddAppToUserModel';
import AppsCard from './AppsCard';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    margin: 0,
  },
}));

export default ({
  user,
  userApps,
  apps,
  changed,
  onConfirm,
  onSaveChange,
  saving,
  onCheckTick,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    addAppModel: false,
  });

  const toggleAddAppModel = () => {
    setState({ ...state, addAppModel: !state.addAppModel });
  };

  const onAddAppConfirm = (selectedApps) => {
    setState({ ...state, addAppModel: false });
    onConfirm(selectedApps);
  };

  return (
    <Grid container>
      <Grid item md={4} sm={4} xs={12}>
        <Typography variant='h6'>User Information</Typography>
        <VerticalCard
          title={user.displayName}
          image={user.photoURL}
          link={null}
          body={
            <Box>
              <a
                href={`mailto:${user.email}`}
                target='_blank'
                style={{ textDecoration: 'none', fontSize: '1.2em' }}
              >
                {user.email}
              </a>
              <br></br>
              Created: {moment(user.createdAt).fromNow()}
              <br></br>
              Updated: {moment(user.updatedAt).fromNow()}
              <br></br>
              {user.apps && `Apps: ${user.apps.length}`}
            </Box>
          }
        />
      </Grid>
      <Grid item md={8} sm={8} xs={12}>
        <Typography variant='h6'>User Accessibility</Typography>
        <CustomCard
          body={
            <Box>
              <Grid container justify='space-between'>
                <Typography variant='subtitle1'>Applications</Typography>
                {apps.length > 0 && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={toggleAddAppModel}
                  >
                    Add App
                  </Button>
                )}
              </Grid>
              <AppsCard apps={userApps || []} onCheckTick={onCheckTick} />
            </Box>
          }
          actions={
            changed && (
              <Button
                color='primary'
                variant='contained'
                onClick={onSaveChange}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            )
          }
        />
      </Grid>
      {state.addAppModel && (
        <AddAppDialog
          onCancel={toggleAddAppModel}
          open={state.addAppModel}
          apps={apps}
          onConfirm={onAddAppConfirm}
          userName={user.displayName}
        />
      )}
    </Grid>
  );
};
