import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginPage from "../views/pages/LoginPage";
import SignupPage from "../views/pages/SignupPage";
import AcceptInvitePage from "../views/pages/AcceptInvitePage";
import ForgotPassword from "../views/pages/ForgotPassword";

export default () => {
	return (
		<BrowserRouter>
			<div>
				<Switch>
					<Route exact path="/login" component={LoginPage} />
					<Route exact path="/signup" component={SignupPage} />
					<Route exact path="/forgot_password" component={ForgotPassword} />
					<Route exact path="/invite/accept" component={AcceptInvitePage} />
				</Switch>
			</div>
		</BrowserRouter>
	);
};
