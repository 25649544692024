import {
  blackColor,
  primaryColor,
  whiteColor,
  dangerColor,
  successColor,
} from "../base";
export default (theme) => ({
  topSection: {
    marginBottom: 20,
    padding: 0,
    display: "flex",
    flexWrap: "nowrap",
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  records: {
    padding: 200,
    background: "green",
  },

  infoSection: {
    padding: 10,
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: '-30%',
    // },
  },

  otherSections: {
    padding: 10,
    "& img": {
      maxWidth: "100%",
      objectFit: "cover",
    },
  },

  image: {
    width: 300,
    height: 300,
  },
  texts: {
    textDecoration: "none",
    color: blackColor,
  },

  articleUrl: {
    textDecoration: "none",
    color: blackColor,
    cursor: "pointer",
    "&:hover": {
      color: primaryColor[0],
    },
  },

  name: {
    textDecoration: "none",
    color: primaryColor[0],
    cursor: "pointer",
  },
  hovered: {
    "&:hover": {
      cursor: "pointer",
      color: primaryColor[0],
    },
  },

  artistName: {
    color: primaryColor[0],
    fontWeight: 500,
    fontSize: "1.2em",
  },
  textSection: {
    padding: 10,
  },
  editButton: {
    background: primaryColor[0],
    color: whiteColor,
    padding: "5px 10px",
    "&:hover": {
      background: primaryColor[0],
      opacity: 0.8,
      transition: "all 0.6s ease",
    },
  },
  deleteButton: {
    background: dangerColor[0],
    color: whiteColor,
    padding: "5px 10px",
    "&:hover": {
      background: dangerColor[0],
      opacity: 0.8,
      transition: "all 0.6s ease",
    },
  },
  launchButton: {
    background: successColor[4],
    color: whiteColor,
    padding: "5px 10px",
    "&:hover": {
      background: successColor[3],
      transition: "all 0.6s ease",
    },
  },
  publishButton: {
    background: successColor[0],
    color: whiteColor,
    padding: "5px 10px",
    "&:hover": {
      background: successColor[4],
      opacity: 0.8,
      transition: "all 0.6s ease",
    },
  },
});
