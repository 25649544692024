import { API_CALL } from './types';

const initialState = {
  loading: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${API_CALL}_PENDING`:
      return { ...state, loading: true };
    case `${API_CALL}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case `${API_CALL}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
