import React, { useState } from "react";
import SignupComponent from "../../components/auth/SignupComponent";
import AuthService from "../../../services/authService";
import backendService from "../../../services/backendService";
import CreateOrgPrompt from "../../components/auth/CreateOrgPromptComponent";

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redUrl = urlParams.get("redUrl");

  const [state, setState] = useState({
    authState: {},
  });
  const authHandler = async (response) => {
    if (response.error) {
      setState({
        ...state,
        authState: {
          ...response,
          loading: false,
          error: true,
        },
      });
    } else {
      const serverResponse = await backendService({
        path: "/auth/verify",
        method: "PATCH",
        data: response,
      });
      if (serverResponse.error)
        setState({
          ...state,
          authState: {
            message: serverResponse.message,
            loading: false,
            error: true,
          },
        });
      else {
        if (response.isNew) {
          // prompt create organization
          setState({
            ...state,
            authState: {},
            user: response,
            orgPrompt: true,
            loading: false,
          });
        } else {
          onSkip();
        }
      }
    }
  };

  const loginWithGoogle = async () => {
    setState({
      ...state,
      authState: {
        loading: true,
      },
    });
    const response = await AuthService.loginWithGoogle();
    await authHandler(response);
  };

  const onSignup = async (email, password, firstName, lastName) => {
    setState({
      ...state,
      authState: {
        loading: true,
      },
    });
    const response = await AuthService.signupWithPassword(
      email,
      password,
      firstName,
      lastName,
      redUrl
    );
    await authHandler(response);
  };

  const onCreateOrg = async () => {
    setState({
      ...state,
      authState: {
        loading: true,
      },
    });
    const response = await backendService({
      method: "POST",
      path: "/auth/orgs",
      data: {
        name: state.name,
        email: state.email,
        phoneNumber: state.phone,
        user: state.user,
      },
    });
    if (response.error) {
      setState({
        ...state,
        authState: {
          ...response,
          loading: false,
          error: true,
        },
      });
    } else {
      onSkip();
    }
  };

  const onSkip = () => {
    window.location.href = redUrl || "https://demand.noneho.com";
  };

  return (
    (state.orgPrompt && (
      <CreateOrgPrompt
        OnChange={(field, value) => {
          setState({
            ...state,
            [field]: value,
          });
        }}
        authState={state.authState}
        onContinue={onCreateOrg}
        onSkip={onSkip}
      />
    )) || (
      <SignupComponent
        loginWithGoogle={loginWithGoogle}
        authState={state.authState}
        onSignup={onSignup}
        redUrl={redUrl}
      />
    )
  );
};
