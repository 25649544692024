import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Mail from "@material-ui/icons/Mail";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../../components/Inputs/TextField";
import Alert from "../../../components/alerts/FixedAlert";
import authComponentStyles from "../../../assets/styles/components/authComponentStyles";

const useStyles = makeStyles((theme) => authComponentStyles(theme));

export default ({ authState, sendPasswordReset }) => {
	const classes = useStyles();
	const [state, setState] = useState({
		email: "",
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.id]: event.target.value, typing: true });
	};

	const handleSendPasswordReset = () => {
		const { email } = state;
		sendPasswordReset(email);
		setState({ ...state, typing: false });
	};

	const { loading, error, message } = authState;

	return (
		<Box>
			<Grid container justify="center" direction="column">
				<TextField
					label="Email"
					fullWidth
					onChange={handleChange}
					id="email"
					type="email"
					EndAdornment={() => <Mail />}
				/>
			</Grid>
			<Grid container justify="center" direction="column">
				{error && !state.typing ? (
					<Alert type="error" message={message} title="" />
				) : null}
				{loading ? (
					<CircularProgress
						variant="indeterminate"
						className={classes.progress}
					/>
				) : null}
				<Button
					onClick={handleSendPasswordReset}
					disabled={loading}
					className={classes.loginButton}
				>
					Reset Password
				</Button>
			</Grid>
		</Box>
	);
};
