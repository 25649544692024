import React, { useState } from "react";
import ForgotPasswordComponent from "../../components/auth/ForgotPassword";
import AuthService from "../../../services/authService";
import Toast from "../../../components/alerts/Toastify";

export default () => {
	const urlParams = new URLSearchParams(window.location.search);
	const redUrl = urlParams.get("redUrl");

	const [state, setState] = useState({
		authState: {},
	});

	const sendPasswordReset = async (email) => {
		setState({
			...state,
			authState: {
				loading: true,
			},
		});
		const response = await AuthService.sendPasswordReset(email, redUrl);
		if (response.error) {
			setState({
				...state,
				authState: {
					...response,
					loading: false,
					error: true,
				},
			});
		} else {
			Toast({
				duration: 7000,
				type: "info",
				message: response.message,
				onClose: () => window.location.assign("/login"),
			});
		}
	};

	return (
		<ForgotPasswordComponent
			sendPasswordReset={sendPasswordReset}
			authState={state.authState}
		/>
	);
};
