import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ArchiveIcon from '@material-ui/icons/Archive';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MenuIconHoriz from '@material-ui/icons/MoreHoriz';
import MenuIcon from '@material-ui/icons/MoreVert';
import Publish from '@material-ui/icons/Publish';
import Restore from '@material-ui/icons/Restore';
import FeaturingIcon from '@material-ui/icons/Stars';
import React from 'react';
import { grayColor, primaryColor, whiteColor } from '../../assets/styles/base';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        style={{
          color: props.background ? whiteColor : grayColor[1],
          backgroundColor: props.background ? primaryColor[0] : 'none',
        }}
        onClick={handleClick}
      >
        <Tooltip placement='top' title='More options' arrow>
          {props.horiz ? <MenuIconHoriz /> : <MenuIcon />}
        </Tooltip>
      </IconButton>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.menu &&
          props.menu.map(
            (el) =>
              !el.hidden && (
                <MenuItem onClick={el.onClick}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.text} />
                </MenuItem>
              )
          )}
      </StyledMenu>
    </div>
  );
};
