import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CreateAppComponent from "../../components/apps/CreateAppComponent";
import apiService from "../../../services/backendService";
import Toast from "../../../components/alerts/Toastify";

export default () => {
  const history = useHistory();
  const [state, setState] = useState({});
  const onAppCreate = async (app) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await apiService({
      method: "POST",
      path: "/api/apps",
      data: app,
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) history.push("/apps");
      },
    });
  };
  return (
    <CreateAppComponent onAppCreate={onAppCreate} loading={state.loading} />
  );
};
