import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Mail from "@material-ui/icons/Mail";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../../components/Inputs/TextField";
import googleLogo from "../../../assets/images/googleLogo.png";
import Alert from "../../../components/alerts/FixedAlert";
import authComponentStyles from "../../../assets/styles/components/authComponentStyles";

const useStyles = makeStyles((theme) => authComponentStyles(theme));

export default ({ onSignup, loginWithGoogle, authState, redUrl }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    showPassword: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value, typing: true });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleSignup = () => {
    const { email, password, firstName, lastName } = state;
    onSignup(email, password, firstName, lastName);
    setState({ ...state, typing: false });
  };

  const { loading, error, message } = authState;

  return (
    <Box>
      <Grid container justify="center" direction="column">
        <TextField
          label="Firstname"
          fullWidth
          id="firstName"
          onChange={handleChange}
        />
        <TextField
          label="Lastname"
          fullWidth
          id="lastName"
          onChange={handleChange}
        />
        <TextField
          label="Email"
          fullWidth
          id="email"
          type="email"
          onChange={handleChange}
          EndAdornment={() => <Mail />}
        />
        <TextField
          label="Password"
          fullWidth
          id="password"
          onChange={handleChange}
          type={state.showPassword ? "text" : "password"}
          EndAdornment={() => (
            <IconButton onClick={handleClickShowPassword}>
              {state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
        />
      </Grid>
      <Grid container justify="center" direction="column">
        {message && !state.typing ? (
          <Alert type={error ? "error" : "info"} message={message} title="" />
        ) : null}
        <Typography align="center">
          Have an account alreaady &nbsp;
          <Link to={`/login?redUrl=${redUrl || "/"}`} className={classes.link}>
            Login
          </Link>
        </Typography>
        {loading ? (
          <CircularProgress
            variant="indeterminate"
            className={classes.progress}
          />
        ) : null}
        <Button
          onClick={handleSignup}
          disabled={loading}
          className={classes.loginButton}
        >
          Signup
        </Button>
        <Typography align="center">Or</Typography>
        <Button
          onClick={loginWithGoogle}
          disabled={loading}
          className={classes.googleButton}
        >
          <Grid container justify="center" alignItems="center">
            <img src={googleLogo} alt="" className={classes.authIcon} />
            &nbsp;Signup With Google
          </Grid>
        </Button>
      </Grid>
    </Box>
  );
};
