import React, { useState } from 'react';
import Select from 'react-select';
import withStyles from '@material-ui/core/styles/withStyles';

export default ({
  options,
  value,
  classes,
  defaultValue,
  loading,
  searching,
  isMulti,
  onChange,
}) => {
  const handleChange = (newValue) => {
    onChange(newValue);
  };
  return (
    <Select
      isSearchable
      isLoading={searching || loading}
      closeMenuOnSelect={false}
      defaultValue={defaultValue}
      isMulti={isMulti}
      onChange={handleChange}
      options={options}
      placeholder={value}
    />
  );
};
