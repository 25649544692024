import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export default responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#1D5380',
        light: '#6096c9',
      },
      common: {
        black: '#1D5380',
        white: '#e8ecef',
      },
      error: {
        main: '#bb2a2afa',
        light: '#ef3e3ee3',
      },
    },
    typography: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      letterSpacing: 10,
    },
  })
);
