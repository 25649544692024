import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import ForgotPasswordContainer from "../containers/auth/ForgotPassword";

export default () => {
	return (
		<AuthLayout title="Forgot password? Provide your email">
			<ForgotPasswordContainer />
		</AuthLayout>
	);
};
