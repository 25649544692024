import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default ({ title }) => {
  return (
    <Box padding={4}>
      <Grid container justify='center' alignItems='center' direction='column'>
        <CircularProgress />
        <Typography>{title || 'Loading contents'}</Typography>
      </Grid>
    </Box>
  );
};
