import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InviteUserComponent from '../../components/users/InviteUserComponent';
import apiActionCreator from '../../../redux/actions/apiActionCreator';
import { FETCH_ALL_APPS } from '../../../redux/apps/types';
import { FETCH_ALL_ORGS } from '../../../redux/orgs/types';
import Alert from '../../../components/alerts/FixedAlert';
import Toast from '../../../components/alerts/Toastify';
import Loading from '../../../components/alerts/Loading';
import backendService from '../../../services/backendService';

export default () => {
  const dispatch = useDispatch();
  const orgs = useSelector((store) => store.allOrgs);
  const apps = useSelector((store) => store.allApps);
  useEffect(() => {
    if (!apps.data.results)
      dispatch(
        apiActionCreator({
          method: 'GET',
          path: '/api/apps',
          actionType: FETCH_ALL_APPS,
        })
      );
    if (!orgs.data.results)
      dispatch(
        apiActionCreator({
          method: 'GET',
          path: '/api/orgs',
          actionType: FETCH_ALL_ORGS,
        })
      );
  }, []);
  const onInviteUser = async (inviteData) => {
    const response = await backendService({
      method: 'POST',
      path: '/api/users/invite',
      data: inviteData,
    });
    Toast({
      message: response.message,
      type: response.error ? 'error' : 'success',
      duration: 5000,
    });
  };
  return (
    <InviteUserComponent
      apps={apps.data.results || []}
      orgs={orgs.data.results || []}
      onInviteUser={onInviteUser}
    />
  );
};
