import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    backgroundSize: 'cover',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardActionsArea: {
    '&:hover': {
      cursor: 'default',
    },
  },
});

export default ({ image, title, body, link, elevation, useCardMedia }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={elevation || 1}>
      <CardActionArea className={classes.cardActionsArea}>
        {useCardMedia ? (
          <CardMedia
            className={classes.media}
            image={image || 'Unknown'}
            title={title}
          />
        ) : (
          <img src={image} className={classes.media} />
        )}
        <CardContent>
          <Typography gutterBottom variant='subtitle1' component='h2'>
            {title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='div'>
            {body}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        {link && (
          <Button size='small' color='primary' component={Link} to={link}>
            View
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
