import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
  },
  inviteContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '-10px 5px 0 0',
  },
}));

export default ({ users }) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {users &&
        users.map((user, index) => (
          <div key={index}>
            <ListItem alignItems='flex-start'>
              <Link to={`/users/${user.uid}`} className={classes.link}>
                <ListItemAvatar>
                  <Avatar alt='Photo' variant='square' src={user.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  style={{ color: '#000' }}
                  primary={user.displayName}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component='span'
                        variant='body2'
                        className={classes.inline}
                        color='textPrimary'
                      >
                        {user.email}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </Link>
              <ListItemSecondaryAction>
                <UserMenu user={user} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant='inset' component='li' />
          </div>
        ))}
    </List>
  );
};
