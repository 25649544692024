import React from 'react'
import AppLayout from '../../layouts/DashboardLayout'

export default ()=> {
    return (
        <AppLayout>
            <h1>Welcome to Noneho Platform</h1>
        </AppLayout>
    )
}
